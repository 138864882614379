import React from 'react';
import cx from 'classnames';

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/600.css";

import { GermanAdesso_SelectFlowModel } from "../renditions";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Language } from '@criipto/verify-react';
import Frame from '../components/Frame';

import styles from './SelectFlow.module.css';
import { LocalizedTexts } from '../components/Language';

import * as images from './img';

export type SelectFlowProps = {
  model: GermanAdesso_SelectFlowModel
}

export default function SelectFlow(props: SelectFlowProps) {
  const {model} = props;
  const {language, sameDeviceUrl, anotherDeviceUrl} = model;

  return (
    <div className={cx(styles.frame, 'de-personalausweis-selector-frame')}>
      <Header
        className={styles.header}
        title={
          <LocalizedTexts
              texts={[
                {language: 'de', text: 'Welches Gerät möchten Sie zum Scannen Ihres Personalausweises nutzen?'},
                {language: 'en', text: 'Where do you want to scan your Personalausweis?'},
              ]}
            />
        }
      />
      <Frame className={cx(styles.container, 'de-personalausweis-selector-button-container')}>
        <div className={cx(styles.content, 'de-personalausweis-selector-content')}>
          <FlowButton
            url={sameDeviceUrl}
            flow={
              <LocalizedTexts
                texts = {[
                  {language: 'de', text: "Dieses Gerät"},
                  {language: 'en', text: "On this device"},
                ]}
              />
            }
            classNameAppendix='same-device'
          />
          <FlowButton
            url={anotherDeviceUrl}
            flow={
              <LocalizedTexts
                texts = {[
                  {language: 'de', text: "Ein anderes Gerät (QR-Code)"},
                  {language: 'en', text: "Another device (QR Code)"},
                ]}
              />
            }
            classNameAppendix='another-device'
          />
        </div>
        <div className={styles.logo}>
            <img src={images.appLogoEn} className="lang lang-en" />
            <img src={images.appLogoDe} className="lang lang-de" />
          </div>
      </Frame>
      <Footer language={language as Language} />
    </div>
  )
}

function FlowButton(props: {url : string, flow: React.ReactElement, classNameAppendix: string}) {
  return (
    <div className={cx(styles.flowButton, 'de-personalausweis-flow-button', props.classNameAppendix)}>
      <a href={props.url}>
        <div className={styles.flowImage}>
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
            <g clipPath="url(#clip0_58_2413)">
              <path d="M5.89917 18.9277C5.89917 25.7466 11.0952 31.2895 17.7327 31.9747V27.3279C13.6631 26.677 10.5473 23.1787 10.5473 18.9277C10.5473 14.6756 13.6631 11.1773 17.7327 10.5264V5.88074C11.0952 6.56471 5.89917 12.1088 5.89917 18.9277Z" fill="white"/>
              <path d="M20.1621 10.5264C24.2304 11.1773 27.3487 14.6756 27.3487 18.9277C27.3487 23.1787 24.2304 26.677 20.1621 27.3279V31.9747C26.7996 31.2895 31.9956 25.7466 31.9956 18.9277C31.9956 12.1088 26.7996 6.56471 20.1621 5.88074V10.5264Z" fill="white"/>
              <path d="M36.9348 31.8015V6.02665C36.9348 6.02665 36.9348 0.914062 31.8221 0.914062H6.04757C6.04757 0.914062 0.934814 0.914062 0.934814 6.02665V31.8015C0.934814 31.8015 0.934814 36.9141 6.04757 36.9141H31.8221C31.8221 36.9141 36.9348 36.9141 36.9348 31.8015ZM35.8642 31.5822C35.8642 35.8423 31.604 35.8423 31.604 35.8423H6.26561C2.00538 35.8423 2.00538 31.5822 2.00538 31.5822V6.2459C2.00538 1.98582 6.26561 1.98582 6.26561 1.98582H31.604C35.8642 1.98582 35.8642 6.2459 35.8642 6.2459V31.5822Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_58_2413">
                <rect width="36" height="36" fill="white" transform="translate(0.947266 0.914062)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        {props.flow}
      </a>
    </div>
  )
}