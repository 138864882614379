import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as QRCode from 'qrcode';

import { Action, SwedishBankID_AnotherDeviceQrModel } from '../renditions';
import Frame from '../components/Frame';
import Footer from '../components/Footer';

import './SwedishBankID.css';
import { Language } from '@criipto/verify-react';
import { LocalizedTexts } from '../components/Language';
import ActionHeader from '../components/ActionHeader';

export default function AnotherDeviceQr(props: {
  model: SwedishBankID_AnotherDeviceQrModel,
  action: Action,
}) {
  const {model, action} = props;
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [allowRetry, setAllowRetry] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [qrCode, setQrCode] = useState(model.initialQrCode);

  useEffect(() => {
    if (error) document.body.classList.add('has-error');
    else document.body.classList.remove('has-error');
  }, [error]);

  useEffect(() => {
    if (!canvasRef.current) return;
    QRCode.toCanvas(canvasRef.current, qrCode, {
      width: canvasRef.current.offsetWidth
    }, function (err) {
        if (err) return handleError(err.toString());
    });
  }, [qrCode]);

  const poll = useCallback(async () => {
    const response = await fetch(model.pollUrl);
    if (response.status >= 400) {
      handleError(await response.clone().text());
      return response.status;
    }
    const payload : {qrCode: string} | {targetUrl: string} = await response.json();

    if ("qrCode" in payload) {
      setQrCode(payload.qrCode);
    }
    if ("targetUrl" in payload) {
      window.location.href = payload.targetUrl;
    }

    return response.status;
  }, [model.pollUrl]);

  useEffect(() => {
    let subscribed = true;

    const recursivePoll = async (delay: number) => {
      if (!subscribed) return;
      try {
        const status = await poll();
        if (status === 202) setTimeout(() => recursivePoll(delay), delay);
      } catch (err) {
        setTimeout(() => recursivePoll(delay), delay);
      }
    }
    
    recursivePoll(2500);

    return () => {
      subscribed = false;
    }
  }, [model.pollUrl]);

  const handleError = function (error: string) {
    if (error == 'Collect failed: startFailed') {
      setAllowRetry(true);
    }
    setError(error);
  };

  const handleSubmit = (event: React.FormEvent) => {
    if (submitted) {
      event.preventDefault();
      return;
    }
    setSubmitted(true);
  };

  const handleRetry = () => {
    window.location.href = window.location.href;
  }

  return (
    <React.Fragment>
      <ActionHeader
        action={action}
        language={model.language}
        suffix="BankID"
      />
      <Frame className="centered sebankid-another-device-qr">
        <div className="default-hidden has-error-show">
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Sorry, but something went wrong. Please try again in a few minutes.`},
                {language: 'sv', text: `Förlåt, men något gick fel. Försök gärna igen om en liten stund.`}
              ]
            }
          />
          <p id="error" className="error">{error}</p>
          <button type="button" className={`${allowRetry ? '' : 'default-hidden'} button button-primary`} id="sebankid_anotherdevice_retry" onClick={handleRetry}>
            <LocalizedTexts
              texts={
                [
                  {language: 'en', text: `Retry`},
                  {language: 'sv', text: `Försök igen`}
                ]
              }
            />
          </button>
        </div>

        <p className="qr-code-help-text has-error-hide">
          <LocalizedTexts
            texts={
              [
                {language: 'en', text: `Open the BankID-app on your phone and press the QR symbol`},
                {language: 'sv', text: `Öppna BankID-appen i mobilen och tryck på QR-symbolen`}
              ]
            }
          />  
        </p>

        <div className="centered qr-code has-error-hide">
          <canvas id="sebankid_another_device_qr_code" ref={canvasRef} />
        </div>
      </Frame>
      <Footer language={model.language as Language} />
    </React.Fragment>
  );
}
